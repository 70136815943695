import React, { useState } from 'react';
import { Toast } from 'react-bootstrap';

import { useStore, useStoreDispatch } from '../context';
import './notification.scss';

const Notification = () => {
  const { notification } = useStore();
  const { setNotification } = useStoreDispatch();

  const closeNotification = (index) => {
    setNotification(notification.filter((_, i) => i !== index));
  };

  return (
    <div className="notificationPanel">
      {notification.map((msg, index) => {
        const message = msg[0];
        return (
          <Toast key={index} onClose={() => closeNotification(index)}>
            <Toast.Header>
              <strong className="mr-auto">RxFoodApp</strong>
            </Toast.Header>
            <Toast.Body>{message}</Toast.Body>
          </Toast>
        );
      })}
    </div>
  );
};

export default Notification;
