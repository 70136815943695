/* eslint-disable i18next/no-literal-string */
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import React, { useState } from 'react';
import { patientApi } from '../api';
import {
  ClinicianPatientsTableResponseRow,
  ClinicianResponse,
  ClinicResponse,
  PatientReportResponse,
  PatientResponse,
  RxFeatureFlagsResponse,
} from '../api/generated/models';
import { useTranslation } from '../i18n';

type StoreContext = {
  patient: ClinicianPatientsTableResponseRow | null,
  clinician: ClinicianResponse & {
    clinics: ClinicResponse[],
  },
  allReports: PatientReportResponse[],
} & Record<string, any>;

const StoreContext = React.createContext<StoreContext>(undefined);
const StoreDispatchContext = React.createContext(undefined);

function StoreProvider({ children }) {
  // local storage for token
  const browserStore = JSON.parse(localStorage.getItem('store') || '{}');
  const { t } = useTranslation();
  const defaultStore = {
    token: null,
    clinician: {
      clinics: [],
    },
    nutrients: [],
    water: [],
    meals: [],
    goals: [],
    notification: [],
    patient: null,
    patientSummary: {},
    patientList: [],
    dash: [],
    emotion: [],
    emotionQuestion: [],
    patientClinics: [],
    appointments: [],
    patientSubscriptions: [],
    draftPatientReports: [],
    isInternalUser: false,
    tipHistory: [],
    allReports: [],
    current_user: null,
    patientListFilters: {},
  };

  Object.keys(defaultStore).forEach(key => {
    if (key in browserStore) {
      defaultStore[key] = browserStore[key];
    }
  });

  const [store, setStore] = useState(defaultStore);

  const setPatientListFilters = (patientListQueryParams) =>
    setStore(prevStore => {
      return { ...prevStore, patientListFilters: patientListQueryParams };
    });

  const setCurrentUser = (current_user) =>
    setStore(prevStore => {
      return { ...prevStore, current_user };
    });

  const setNutrients = (nutrients) =>
    setStore(prevStore => {
      return { ...prevStore, nutrients };
    });

  const setWater = (water) =>
    setStore(prevStore => {
      return { ...prevStore, water };
    });

  const setEmotion = (emotion) =>
    setStore(prevStore => {
      return { ...prevStore, emotion };
    });

  const setEmotionQuestion = (emotionQuestion) =>
    setStore(prevStore => {
      return { ...prevStore, emotionQuestion };
    });

  const setAppointments = (appointments) =>
    setStore(prevStore => {
      return { ...prevStore, appointments };
    });

  const setPatientSubscriptions = (patientSubscriptions) =>
    setStore(prevStore => {
      return { ...prevStore, patientSubscriptions };
    });

  const setDraftPatientReports = (draftPatientReports) =>
    setStore(prevStore => {
      return { ...prevStore, draftPatientReports };
    });

  const setIsInternalUser = (isInternalUser) =>
    setStore(prevStore => {
      return { ...prevStore, isInternalUser };
    });

  const setAllPatientReports = (allReports: PatientReportResponse[]) =>
    setStore(prevStore => {
      return { ...prevStore, allReports };
    });

  const setHEI = (hei) =>
    setStore(prevStore => {
      return { ...prevStore, hei };
    });

  const setDash = (dash) =>
    setStore(prevStore => {
      return { ...prevStore, dash };
    });

  const setClinician = (clinician) =>
    setStore(prevStore => {
      return { ...prevStore, clinician };
    });

  const setPatient = (patient) =>
    setStore(prevStore => {
      return { ...prevStore, patient };
    });

  const setPatientClinics = (patientClinics) =>
    setStore((prevStore: any) => {
      return { ...prevStore, patientClinics: {} };
    });

  const setPatientInAClinic = (patientInAClinic) =>
    setStore(prevStore => {
      return { ...prevStore, patientInAClinic: {} };
    });

  const setTipHistory = (tipHistory) =>
    setStore(prevStore => {
      return { ...prevStore, tipHistory };
    });

  const setPatientSummary = (patientSummary) =>
    setStore(prevStore => {
      if (!patientSummary) {
        return { ...prevStore, patientSummary: {} };
      }

      let fiberAnalysis = 'at target';
      if (patientSummary.sex == 'male') {
        if (patientSummary.avg_fiber_g > 35) {
          fiberAnalysis = 'high';
        } else if (patientSummary.avg_fiber_g > 25) {
          fiberAnalysis = 'at target';
        } else {
          fiberAnalysis = 'low';
        }
      } else if (patientSummary.sex == 'female') {
        if (patientSummary.avg_fiber_g > 25) {
          fiberAnalysis = 'high';
        } else if (patientSummary.avg_fiber_g > 20) {
          fiberAnalysis = 'at target';
        } else {
          fiberAnalysis = 'low';
        }
      }

      let carbAnalysis = 'at target';
      if (patientSummary.avg_carbs_g > 300) {
        carbAnalysis = 'high';
      } else if (patientSummary.avg_carbs_g < 50) {
        carbAnalysis = 'low';
      }

      let fatAnalysis = 'at target';
      if (patientSummary.avg_fat_g > 90) {
        fatAnalysis = 'high';
      } else if (patientSummary.avg_fat_g < 30) {
        fatAnalysis = 'low';
      }

      let proteinAnalysis = 'at target';
      if (patientSummary.avg_protein_g > 90) {
        proteinAnalysis = 'high';
      } else if (patientSummary.avg_protein_g < 30) {
        proteinAnalysis = 'low';
      }

      let sodiumAnalysis = 'at target';
      if (patientSummary.avg_sodium_mg > 3000) {
        sodiumAnalysis = 'high';
      } else if (patientSummary.avg_sodium_mg < 500) {
        sodiumAnalysis = 'low';
      }

      let calorieAnalysis = 'at target';
      if (patientSummary.avg_calories_kcal > 3000) {
        calorieAnalysis = 'high';
      } else if (patientSummary.avg_calories_kcal < 500) {
        calorieAnalysis = 'low';
      }
      const nutrients = {
        calories: {
          value: patientSummary.avg_calories_kcal,
          analysis: calorieAnalysis,
        },
        carbs: {
          value: patientSummary.avg_carbs_g,
          analysis: carbAnalysis,
        },
        fat: {
          value: patientSummary.avg_fat_g,
          analysis: fatAnalysis,
        },
        protein: {
          value: patientSummary.avg_protein_g,
          analysis: proteinAnalysis,
        },
        sodium: {
          value: patientSummary.avg_sodium_mg,
          analysis: sodiumAnalysis,
        },
        fiber: {
          value: patientSummary.avg_fiber_g,
          analysis: fiberAnalysis,
        },
        calcium: {
          value: patientSummary.avg_calcium_mg,
          analysis: fiberAnalysis,
        },
      };

      const frequency = {
        processed: [
          {
            name: t('ultra & high processed'),
            value: (patientSummary.frequency_high_processed * 100).toFixed(0),
            analysis: patientSummary.frequency_high_processed > 0.5 ? 'high' : 'at target',
          },
          {
            name: t('min processed'),
            value: (patientSummary.frequency_min_processed * 100).toFixed(0),
            analysis: 'low',
          },
        ],
        meat: [
          {
            name: t('red meat'),
            value: (patientSummary.frequency_redmeat * 100).toFixed(0),
            analysis: patientSummary.frequency_redmeat > 0.2 ? 'high' : 'at target',
          },
          {
            name: t('poultry'),
            value: (patientSummary.frequency_poultry * 100).toFixed(0),
            analysis: 'low',
          },
          {
            name: t('fish'),
            value: (patientSummary.frequency_fish * 100).toFixed(0),
            analysis: 'low',
          },
          {
            name: t('plant'),
            value: (patientSummary.frequency_plant * 100).toFixed(0),
            analysis: 'low',
          },
        ],
        fiber: [
          {
            name: t('high fiber'),
            value: (patientSummary.frequency_high_fiber * 100).toFixed(0),
            analysis: patientSummary.frequency_high_fibre < 0.6 ? 'high' : 'at target',
          },
          {
            name: t('low fiber'),
            value: (patientSummary.frequency_low_fiber * 100).toFixed(0),
            analysis: 'low',
          },
        ],
        fried: [
          {
            name: t('fried'),
            value: (patientSummary.frequency_fried * 100).toFixed(0),
            analysis: patientSummary.frequency_friend > 0.2 ? 'high' : 'at target',
          },
          {
            name: t('not fried'),
            value: (patientSummary.frequency_not_fried * 100).toFixed(0),
            analysis: 'low',
          },
        ],
      };

      patientSummary.frequency = frequency;
      patientSummary.nutrients = nutrients;

      return { ...prevStore, patientSummary };
    });

  const setPatientList = (patientList) =>
    setStore(prevStore => {
      return { ...prevStore, patientList };
    });

  const setMeals = (meals) =>
    setStore(prevStore => {
      return { ...prevStore, meals };
    });

  const setGoals = (goals) =>
    setStore(prevStore => {
      return { ...prevStore, goals };
    });

  const setToken = (token) =>
    setStore(prevStore => {
      return { ...defaultStore, token };
    });

  const addNotification = (msg: [string, boolean]) =>
    setStore(prevStore => {
      let { notification = [] } = prevStore;
      if (notification.every(notice => !notice[1])) {
        notification = [];
      }

      notification.push([msg, true]);
      return { ...prevStore, notification };
    });

  const setNotification = (notification) =>
    setStore(prevStore => {
      return { ...prevStore, notification };
    });

  const clearUser = () => {
    const newStore = Object.assign({}, defaultStore);
    newStore.token = store.token;
    newStore.clinician = store.clinician;
    newStore.current_user = store.current_user;
    setStore(newStore);
  };

  const reset = () => {
    defaultStore.token = null;
    setStore(defaultStore);
  };
  // local storage for token
  localStorage.setItem('store', JSON.stringify(store));
  return (
    <StoreContext.Provider value={store as any}>
      <StoreDispatchContext.Provider
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        value={{
          setNutrients,
          setWater,
          setEmotion,
          setEmotionQuestion,
          setHEI,
          setDash,
          setClinician,
          setPatientList,
          setPatient,
          setMeals,
          setToken,
          setCurrentUser,
          setPatientListFilters,
          reset,
          setGoals,
          setPatientSummary,
          setNotification,
          addNotification,
          setPatientClinics,
          setAppointments,
          setPatientSubscriptions,
          setDraftPatientReports,
          setIsInternalUser,
          setAllPatientReports,
          setPatientInAClinic,
          setTipHistory,
          clearUser,
        }}
      >
        {children}
      </StoreDispatchContext.Provider>
    </StoreContext.Provider>
  );
}

function useStore() {
  const context = React.useContext(StoreContext);
  if (context === undefined) {
    throw new Error('useStore must be used within a StoreProvider');
  }
  return context;
}

function useStoreDispatch() {
  const context = React.useContext(StoreDispatchContext);
  if (context === undefined) {
    throw new Error('useStoreDispatch must be used within a StoreProvider');
  }
  return context;
}

const useCurrentPatientData = () => {
  const patient = useStore().patient;
  const patientQuery = useQuery(['patient', patient.patient_id], async () => {
    if (!patient) {
      return null;
    }
    const res = await patientApi.appApiPatientGetPatient({
      patient_id: patient.patient_id,
    });
    return res.data;
  });
  return {
    query: patientQuery,
    patientData: patientQuery.data,
    flags: (patientQuery.data?.flags || {}) as RxFeatureFlagsResponse,
  };
};

export { StoreProvider, useCurrentPatientData, useStore, useStoreDispatch };
