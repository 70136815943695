/* tslint:disable */
/* eslint-disable */
/**
 * InnerAnalytics MNT Server
 * InnerAnalytics MNT server API specification.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ExternalOauthTokenFlowAuthorization } from '../models';
// @ts-ignore
import { UserAuthorizationResponse } from '../models';
/**
 * OauthApi - axios parameter creator
 * @export
 */
export const OauthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a URL that authorizes a new third-party OAuth flow
         * @param {string} [provider_name] 
         * @param {string} [provider_endpoint] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiExternalOauthGetAuthorizationUrl: async (provider_name?: string, provider_endpoint?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/current/external_oauth_authorization`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (provider_name !== undefined) {
                localVarQueryParameter['provider_name'] = provider_name;
            }

            if (provider_endpoint !== undefined) {
                localVarQueryParameter['provider_endpoint'] = provider_endpoint;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get auth token (with mfa support)
         * @param {string} username 
         * @param {string} password 
         * @param {string} response_type indicate token here
         * @param {string} client_id 
         * @param {string} redirect_uri 
         * @param {string} [token] mfa token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiOauthAuthorize: async (username: string, password: string, response_type: string, client_id: string, redirect_uri: string, token?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('appApiOauthAuthorize', 'username', username)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('appApiOauthAuthorize', 'password', password)
            // verify required parameter 'response_type' is not null or undefined
            assertParamExists('appApiOauthAuthorize', 'response_type', response_type)
            // verify required parameter 'client_id' is not null or undefined
            assertParamExists('appApiOauthAuthorize', 'client_id', client_id)
            // verify required parameter 'redirect_uri' is not null or undefined
            assertParamExists('appApiOauthAuthorize', 'redirect_uri', redirect_uri)
            const localVarPath = `/oauth/authorize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (username !== undefined) { 
                localVarFormParams.set('username', username as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.set('password', password as any);
            }
    
            if (token !== undefined) { 
                localVarFormParams.set('token', token as any);
            }
    
            if (response_type !== undefined) { 
                localVarFormParams.set('response_type', response_type as any);
            }
    
            if (client_id !== undefined) { 
                localVarFormParams.set('client_id', client_id as any);
            }
    
            if (redirect_uri !== undefined) { 
                localVarFormParams.set('redirect_uri', redirect_uri as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Temporary token for clinician <> auditor authentication
         * @param {string} [token] 
         * @param {string} [response_type] indicate token here
         * @param {string} [client_id] 
         * @param {string} [redirect_uri] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiOauthAuthorizeAuditorToken: async (token?: string, response_type?: string, client_id?: string, redirect_uri?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/oauth/authorize_auditor_token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (token !== undefined) { 
                localVarFormParams.set('token', token as any);
            }
    
            if (response_type !== undefined) { 
                localVarFormParams.set('response_type', response_type as any);
            }
    
            if (client_id !== undefined) { 
                localVarFormParams.set('client_id', client_id as any);
            }
    
            if (redirect_uri !== undefined) { 
                localVarFormParams.set('redirect_uri', redirect_uri as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get auth token using a secure token
         * @param {string} secure_token 
         * @param {string} response_type indicate token here
         * @param {string} client_id 
         * @param {string} redirect_uri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiOauthAuthorizeMagicLink: async (secure_token: string, response_type: string, client_id: string, redirect_uri: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'secure_token' is not null or undefined
            assertParamExists('appApiOauthAuthorizeMagicLink', 'secure_token', secure_token)
            // verify required parameter 'response_type' is not null or undefined
            assertParamExists('appApiOauthAuthorizeMagicLink', 'response_type', response_type)
            // verify required parameter 'client_id' is not null or undefined
            assertParamExists('appApiOauthAuthorizeMagicLink', 'client_id', client_id)
            // verify required parameter 'redirect_uri' is not null or undefined
            assertParamExists('appApiOauthAuthorizeMagicLink', 'redirect_uri', redirect_uri)
            const localVarPath = `/oauth/authorize_magic_link`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (secure_token !== undefined) { 
                localVarFormParams.set('secure_token', secure_token as any);
            }
    
            if (response_type !== undefined) { 
                localVarFormParams.set('response_type', response_type as any);
            }
    
            if (client_id !== undefined) { 
                localVarFormParams.set('client_id', client_id as any);
            }
    
            if (redirect_uri !== undefined) { 
                localVarFormParams.set('redirect_uri', redirect_uri as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get auth token (to be used from swagger ui)
         * @param {string} username 
         * @param {string} password 
         * @param {string} response_type indicate token here
         * @param {string} client_id 
         * @param {string} redirect_uri 
         * @param {string} [token] mfa token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiOauthAuthorizeSwagger: async (username: string, password: string, response_type: string, client_id: string, redirect_uri: string, token?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('appApiOauthAuthorizeSwagger', 'username', username)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('appApiOauthAuthorizeSwagger', 'password', password)
            // verify required parameter 'response_type' is not null or undefined
            assertParamExists('appApiOauthAuthorizeSwagger', 'response_type', response_type)
            // verify required parameter 'client_id' is not null or undefined
            assertParamExists('appApiOauthAuthorizeSwagger', 'client_id', client_id)
            // verify required parameter 'redirect_uri' is not null or undefined
            assertParamExists('appApiOauthAuthorizeSwagger', 'redirect_uri', redirect_uri)
            const localVarPath = `/oauth/authorize_swagger`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (username !== undefined) { 
                localVarFormParams.set('username', username as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.set('password', password as any);
            }
    
            if (token !== undefined) { 
                localVarFormParams.set('token', token as any);
            }
    
            if (response_type !== undefined) { 
                localVarFormParams.set('response_type', response_type as any);
            }
    
            if (client_id !== undefined) { 
                localVarFormParams.set('client_id', client_id as any);
            }
    
            if (redirect_uri !== undefined) { 
                localVarFormParams.set('redirect_uri', redirect_uri as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Logout and invalidate the current oauth token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiOauthLogout: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/oauth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OauthApi - functional programming interface
 * @export
 */
export const OauthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OauthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a URL that authorizes a new third-party OAuth flow
         * @param {string} [provider_name] 
         * @param {string} [provider_endpoint] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiExternalOauthGetAuthorizationUrl(provider_name?: string, provider_endpoint?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExternalOauthTokenFlowAuthorization>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiExternalOauthGetAuthorizationUrl(provider_name, provider_endpoint, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get auth token (with mfa support)
         * @param {string} username 
         * @param {string} password 
         * @param {string} response_type indicate token here
         * @param {string} client_id 
         * @param {string} redirect_uri 
         * @param {string} [token] mfa token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiOauthAuthorize(username: string, password: string, response_type: string, client_id: string, redirect_uri: string, token?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAuthorizationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiOauthAuthorize(username, password, response_type, client_id, redirect_uri, token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Temporary token for clinician <> auditor authentication
         * @param {string} [token] 
         * @param {string} [response_type] indicate token here
         * @param {string} [client_id] 
         * @param {string} [redirect_uri] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiOauthAuthorizeAuditorToken(token?: string, response_type?: string, client_id?: string, redirect_uri?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiOauthAuthorizeAuditorToken(token, response_type, client_id, redirect_uri, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get auth token using a secure token
         * @param {string} secure_token 
         * @param {string} response_type indicate token here
         * @param {string} client_id 
         * @param {string} redirect_uri 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiOauthAuthorizeMagicLink(secure_token: string, response_type: string, client_id: string, redirect_uri: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAuthorizationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiOauthAuthorizeMagicLink(secure_token, response_type, client_id, redirect_uri, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get auth token (to be used from swagger ui)
         * @param {string} username 
         * @param {string} password 
         * @param {string} response_type indicate token here
         * @param {string} client_id 
         * @param {string} redirect_uri 
         * @param {string} [token] mfa token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiOauthAuthorizeSwagger(username: string, password: string, response_type: string, client_id: string, redirect_uri: string, token?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiOauthAuthorizeSwagger(username, password, response_type, client_id, redirect_uri, token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Logout and invalidate the current oauth token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async appApiOauthLogout(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.appApiOauthLogout(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OauthApi - factory interface
 * @export
 */
export const OauthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OauthApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a URL that authorizes a new third-party OAuth flow
         * @param {OauthApiAppApiExternalOauthGetAuthorizationUrlRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiExternalOauthGetAuthorizationUrl(requestParameters: OauthApiAppApiExternalOauthGetAuthorizationUrlRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ExternalOauthTokenFlowAuthorization> {
            return localVarFp.appApiExternalOauthGetAuthorizationUrl(requestParameters.provider_name, requestParameters.provider_endpoint, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get auth token (with mfa support)
         * @param {OauthApiAppApiOauthAuthorizeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiOauthAuthorize(requestParameters: OauthApiAppApiOauthAuthorizeRequest, options?: AxiosRequestConfig): AxiosPromise<UserAuthorizationResponse> {
            return localVarFp.appApiOauthAuthorize(requestParameters.username, requestParameters.password, requestParameters.response_type, requestParameters.client_id, requestParameters.redirect_uri, requestParameters.token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Temporary token for clinician <> auditor authentication
         * @param {OauthApiAppApiOauthAuthorizeAuditorTokenRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiOauthAuthorizeAuditorToken(requestParameters: OauthApiAppApiOauthAuthorizeAuditorTokenRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.appApiOauthAuthorizeAuditorToken(requestParameters.token, requestParameters.response_type, requestParameters.client_id, requestParameters.redirect_uri, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get auth token using a secure token
         * @param {OauthApiAppApiOauthAuthorizeMagicLinkRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiOauthAuthorizeMagicLink(requestParameters: OauthApiAppApiOauthAuthorizeMagicLinkRequest, options?: AxiosRequestConfig): AxiosPromise<UserAuthorizationResponse> {
            return localVarFp.appApiOauthAuthorizeMagicLink(requestParameters.secure_token, requestParameters.response_type, requestParameters.client_id, requestParameters.redirect_uri, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get auth token (to be used from swagger ui)
         * @param {OauthApiAppApiOauthAuthorizeSwaggerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiOauthAuthorizeSwagger(requestParameters: OauthApiAppApiOauthAuthorizeSwaggerRequest, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.appApiOauthAuthorizeSwagger(requestParameters.username, requestParameters.password, requestParameters.response_type, requestParameters.client_id, requestParameters.redirect_uri, requestParameters.token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Logout and invalidate the current oauth token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        appApiOauthLogout(options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.appApiOauthLogout(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for appApiExternalOauthGetAuthorizationUrl operation in OauthApi.
 * @export
 * @interface OauthApiAppApiExternalOauthGetAuthorizationUrlRequest
 */
export interface OauthApiAppApiExternalOauthGetAuthorizationUrlRequest {
    /**
     * 
     * @type {string}
     * @memberof OauthApiAppApiExternalOauthGetAuthorizationUrl
     */
    readonly provider_name?: string

    /**
     * 
     * @type {string}
     * @memberof OauthApiAppApiExternalOauthGetAuthorizationUrl
     */
    readonly provider_endpoint?: string
}

/**
 * Request parameters for appApiOauthAuthorize operation in OauthApi.
 * @export
 * @interface OauthApiAppApiOauthAuthorizeRequest
 */
export interface OauthApiAppApiOauthAuthorizeRequest {
    /**
     * 
     * @type {string}
     * @memberof OauthApiAppApiOauthAuthorize
     */
    readonly username: string

    /**
     * 
     * @type {string}
     * @memberof OauthApiAppApiOauthAuthorize
     */
    readonly password: string

    /**
     * indicate token here
     * @type {string}
     * @memberof OauthApiAppApiOauthAuthorize
     */
    readonly response_type: string

    /**
     * 
     * @type {string}
     * @memberof OauthApiAppApiOauthAuthorize
     */
    readonly client_id: string

    /**
     * 
     * @type {string}
     * @memberof OauthApiAppApiOauthAuthorize
     */
    readonly redirect_uri: string

    /**
     * mfa token
     * @type {string}
     * @memberof OauthApiAppApiOauthAuthorize
     */
    readonly token?: string
}

/**
 * Request parameters for appApiOauthAuthorizeAuditorToken operation in OauthApi.
 * @export
 * @interface OauthApiAppApiOauthAuthorizeAuditorTokenRequest
 */
export interface OauthApiAppApiOauthAuthorizeAuditorTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof OauthApiAppApiOauthAuthorizeAuditorToken
     */
    readonly token?: string

    /**
     * indicate token here
     * @type {string}
     * @memberof OauthApiAppApiOauthAuthorizeAuditorToken
     */
    readonly response_type?: string

    /**
     * 
     * @type {string}
     * @memberof OauthApiAppApiOauthAuthorizeAuditorToken
     */
    readonly client_id?: string

    /**
     * 
     * @type {string}
     * @memberof OauthApiAppApiOauthAuthorizeAuditorToken
     */
    readonly redirect_uri?: string
}

/**
 * Request parameters for appApiOauthAuthorizeMagicLink operation in OauthApi.
 * @export
 * @interface OauthApiAppApiOauthAuthorizeMagicLinkRequest
 */
export interface OauthApiAppApiOauthAuthorizeMagicLinkRequest {
    /**
     * 
     * @type {string}
     * @memberof OauthApiAppApiOauthAuthorizeMagicLink
     */
    readonly secure_token: string

    /**
     * indicate token here
     * @type {string}
     * @memberof OauthApiAppApiOauthAuthorizeMagicLink
     */
    readonly response_type: string

    /**
     * 
     * @type {string}
     * @memberof OauthApiAppApiOauthAuthorizeMagicLink
     */
    readonly client_id: string

    /**
     * 
     * @type {string}
     * @memberof OauthApiAppApiOauthAuthorizeMagicLink
     */
    readonly redirect_uri: string
}

/**
 * Request parameters for appApiOauthAuthorizeSwagger operation in OauthApi.
 * @export
 * @interface OauthApiAppApiOauthAuthorizeSwaggerRequest
 */
export interface OauthApiAppApiOauthAuthorizeSwaggerRequest {
    /**
     * 
     * @type {string}
     * @memberof OauthApiAppApiOauthAuthorizeSwagger
     */
    readonly username: string

    /**
     * 
     * @type {string}
     * @memberof OauthApiAppApiOauthAuthorizeSwagger
     */
    readonly password: string

    /**
     * indicate token here
     * @type {string}
     * @memberof OauthApiAppApiOauthAuthorizeSwagger
     */
    readonly response_type: string

    /**
     * 
     * @type {string}
     * @memberof OauthApiAppApiOauthAuthorizeSwagger
     */
    readonly client_id: string

    /**
     * 
     * @type {string}
     * @memberof OauthApiAppApiOauthAuthorizeSwagger
     */
    readonly redirect_uri: string

    /**
     * mfa token
     * @type {string}
     * @memberof OauthApiAppApiOauthAuthorizeSwagger
     */
    readonly token?: string
}

/**
 * OauthApi - object-oriented interface
 * @export
 * @class OauthApi
 * @extends {BaseAPI}
 */
export class OauthApi extends BaseAPI {
    /**
     * 
     * @summary Get a URL that authorizes a new third-party OAuth flow
     * @param {OauthApiAppApiExternalOauthGetAuthorizationUrlRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OauthApi
     */
    public appApiExternalOauthGetAuthorizationUrl(requestParameters: OauthApiAppApiExternalOauthGetAuthorizationUrlRequest = {}, options?: AxiosRequestConfig) {
        return OauthApiFp(this.configuration).appApiExternalOauthGetAuthorizationUrl(requestParameters.provider_name, requestParameters.provider_endpoint, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get auth token (with mfa support)
     * @param {OauthApiAppApiOauthAuthorizeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OauthApi
     */
    public appApiOauthAuthorize(requestParameters: OauthApiAppApiOauthAuthorizeRequest, options?: AxiosRequestConfig) {
        return OauthApiFp(this.configuration).appApiOauthAuthorize(requestParameters.username, requestParameters.password, requestParameters.response_type, requestParameters.client_id, requestParameters.redirect_uri, requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Temporary token for clinician <> auditor authentication
     * @param {OauthApiAppApiOauthAuthorizeAuditorTokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OauthApi
     */
    public appApiOauthAuthorizeAuditorToken(requestParameters: OauthApiAppApiOauthAuthorizeAuditorTokenRequest = {}, options?: AxiosRequestConfig) {
        return OauthApiFp(this.configuration).appApiOauthAuthorizeAuditorToken(requestParameters.token, requestParameters.response_type, requestParameters.client_id, requestParameters.redirect_uri, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get auth token using a secure token
     * @param {OauthApiAppApiOauthAuthorizeMagicLinkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OauthApi
     */
    public appApiOauthAuthorizeMagicLink(requestParameters: OauthApiAppApiOauthAuthorizeMagicLinkRequest, options?: AxiosRequestConfig) {
        return OauthApiFp(this.configuration).appApiOauthAuthorizeMagicLink(requestParameters.secure_token, requestParameters.response_type, requestParameters.client_id, requestParameters.redirect_uri, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get auth token (to be used from swagger ui)
     * @param {OauthApiAppApiOauthAuthorizeSwaggerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OauthApi
     */
    public appApiOauthAuthorizeSwagger(requestParameters: OauthApiAppApiOauthAuthorizeSwaggerRequest, options?: AxiosRequestConfig) {
        return OauthApiFp(this.configuration).appApiOauthAuthorizeSwagger(requestParameters.username, requestParameters.password, requestParameters.response_type, requestParameters.client_id, requestParameters.redirect_uri, requestParameters.token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Logout and invalidate the current oauth token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OauthApi
     */
    public appApiOauthLogout(options?: AxiosRequestConfig) {
        return OauthApiFp(this.configuration).appApiOauthLogout(options).then((request) => request(this.axios, this.basePath));
    }
}

