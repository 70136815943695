import React, { useEffect } from 'react';
import { Col, Row, Table } from 'react-bootstrap';

import { Chart } from 'react-charts';
import { withFilterPanel } from '../components/emotionsFilter';
import { useStore } from '../context';
import { PatientService } from '../services/patient';

import moment from 'moment';
import { RxErrorBoundary } from '../components/RxErrorBoundary';
import { Trans, useTranslation } from '../i18n';
import './patient-emotions.scss';
import { getEmotionOptions } from '../utils/userDataTranslations';

const Emotions = (props) => {
  return (
    <RxErrorBoundary>
      <_Emotions {...props} />
    </RxErrorBoundary>
  );
};

const _Emotions = (props) => {
  const { emotion, emotionQuestion, patient } = useStore();
  const patientService = PatientService();
  const { t } = useTranslation();
  const { filter } = props;

  useEffect(() => {
    const patient_id = patient.patient_id;
    const getDataEmotion = async () => {
      const [since, until] = filter[0].map(m => moment(m).format('YYYY-MM-DD'));
      await patientService.getEmotion(patient_id, since, until);
    };
    getDataEmotion();

    const getDataEmotionQuestion = async () => {
      await patientService.getEmotionQuestion(patient_id);
    };
    getDataEmotionQuestion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  function outputStatementQuestion() {
    if (emotionQuestion.length == 0) {
      return t('Loading');
    }
    return (
      <div className="goalDetail">
        <Trans>User Reported Outcomes:</Trans> {emotion.question}
      </div>
    );
  }

  function outputStatement(status, questions) {
    // if (emotion.length == 0 || emotionQuestion.length == 0) {
    //   return '';
    // }

    return (
      <div>
        {PlotDataEmotion(questions, status)}
        <br />
        <Row>
          {!!questions?.options?.length && questions.options.map((q, idx) => {
            const e1 = status.filter(status => status.emotion === q);
            return (
              <Col key={idx} className="GoalCol summaryTitle last">
                {getEmotionOptions(t).find(o => o.id === q).labelTr}
                <br />
                <Trans>N=</Trans>
                {e1.length}
              </Col>
            );
          })}
        </Row>
        <p className="goalTitle" />
        <Table className="patientDataTable">
          <thead>
            <tr>
              <td>
                <Trans>Log Day</Trans>
              </td>
              <td>
                <Trans>Emotion</Trans>
              </td>
              <td>
                <Trans>Notes</Trans>
              </td>
            </tr>
          </thead>
          <tbody>
            {status?.length == 0
              ? (
                <tr>
                  <td colSpan="3">{t('No emotion data available')}</td>
                </tr>
              )
              : (
                status?.map((item, idx) => {
                  return (
                    <tr key={idx} className="goalTitle">
                      <td>{item.log_date}</td>
                      <td>{getEmotionOptions(t).find(o => o.id === item.emotion).labelTr}</td>
                      <td>{item.note}</td>
                    </tr>
                  );
                })
              )}
          </tbody>
        </Table>
      </div>
    );
  }

  function PlotDataEmotion(dataD1, dataD2) {
    const plotPointsEmotion = [];
    if (dataD1 && dataD2) {
      dataD1 = dataD1.options;
      let countData = 1;
      dataD1?.map(q => {
        let EmotionCount = 0;
        dataD2?.map(item => {
          if (item.emotion == q) {
            EmotionCount++;
          }
        });
        plotPointsEmotion.push([countData, EmotionCount]);
        countData++;
      });
    }
    const dataEmotion = // React.useMemo(
      // () =>
      [
        {
          label: t('Emotions'),
          data: plotPointsEmotion,
        },
      ]; // ,
    // []
    // );

    const seriesEmotion = // React.useMemo(
      // () =>
      {
        type: t('bar'),
      }; // ,
    // []
    // );

    const axesEmotion = // React.useMemo(
      // () =>
      [
        { primary: true, type: 'ordinal', position: 'bottom', show: false },
        { type: 'linear', position: 'left', hardMin: 0 },
      ]; // ,
    // []
    // );

    const barChart = (
      <div
        style={{
          padding: '10px',
          width: '100%',
          height: '300px',
        }}
      >
        <Chart id="emotionPlot" data={dataEmotion} series={seriesEmotion} axes={axesEmotion} tooltip />
      </div>
    );

    return (
      <div>
        {!!dataD2.length && barChart}
      </div>
    );
  }

  return (
    <div>
      <div>
        {outputStatementQuestion()}
        {outputStatement(emotion, emotionQuestion)}
      </div>
    </div>
  );
};

export default withFilterPanel(Emotions);
